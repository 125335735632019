
	melon.templates['users_in_sidebar'] = `{% for (var i=0, l=users.length; i < l; i++) {
	var u = users[i];
%}
	<span class="avatar avatar-small {{ u.avatar_class || "" }}" title="{{ u.title }}">
	{% if (u.icon) { %}
		<i class="{{ u.icon }}"></i>
	{% } else if(u.image) { %}
		<img class="media-object" src="{{ u.image }}" alt="{{ u.fullname }}">
	{% } else { %}
		<div class="standard-image" style="background-color: {{ u.color }};">{{ u.abbr.substr(0,1) }}</div>
	{% } %}
	</span>
{% } %}
`;


	melon.templates['contact_list'] = `<div class="clearfix"></div>
{% for (const contact of contact_list) { %}
	<div class="address-box">
		<a
			href="{%= melon.utils.get_form_link('Contact', contact.name) %}"
			class="btn btn-xs btn-default edit-btn"
			title="{%= __('Edit') %}"
		>
			<svg class="icon icon-xs">
				<use href="#icon-edit"></use>
			</svg>
		</a>
		<p class="h6 flex flex-wrap">
			<span>{%= contact.first_name %} {%= contact.last_name %}</span>
			{% if (contact.is_primary_contact) { %}
				&nbsp;&#183;&nbsp;
				<span class="text-muted">{%= __("Primary Contact") %}</span>
			{% } %}
			{% if (contact.is_billing_contact) { %}
				&nbsp;&#183;&nbsp;
				<span class="text-muted">{%= __("Billing Contact") %}</span>
			{% } %}
			{% if (contact.designation){ %}
				&nbsp;&#183;&nbsp;
				<span class="text-muted"> {%= contact.designation %}</span>
			{% } %}
		</p>
		{% if (contact.phone || contact.mobile_no || contact.phone_nos.length > 0) { %}
		<p>
			{% if (contact.phone) { %}
				<a href="tel:{%= melon.utils.escape_html(contact.phone) %}">
					{%= melon.utils.escape_html(contact.phone) %}
				</a>
				&#183;
				<span class="text-muted">{%= __("Primary Phone") %}</span>
				<br>
			{% endif %}
			{% if (contact.mobile_no) { %}
				<a href="tel:{%= melon.utils.escape_html(contact.mobile_no) %}">
					{%= melon.utils.escape_html(contact.mobile_no) %}
				</a>
				&#183;
				<span class="text-muted">{%= __("Primary Mobile") %}</span>
				<br>
			{% endif %}
			{% if (contact.phone_nos) { %}
				{% for (const phone_no of contact.phone_nos) { %}
					<a href="tel:{%= melon.utils.escape_html(phone_no.phone) %}">
						{%= melon.utils.escape_html(phone_no.phone) %}
					</a>
					<br>
				{% } %}
			{% endif %}
		</p>
		{% endif %}
		{% if (contact.email_id || contact.email_ids.length > 0) { %}
		<p>
			{% if (contact.email_id) { %}
				<a href="mailto:{%= melon.utils.escape_html(contact.email_id) %}">
					{%= melon.utils.escape_html(contact.email_id) %}
				</a>
				&#183;
				<span class="text-muted">{%= __("Primary Email") %}</span>
				<br>
			{% endif %}
			{% if (contact.email_ids) { %}
				{% for (const email_id of contact.email_ids) { %}
					<a href="mailto:{%= melon.utils.escape_html(email_id.email_id) %}">
						{%= melon.utils.escape_html(email_id.email_id) %}
					</a>
					<br>
				{% } %}
			{% endif %}
		</p>
		{% endif %}
		{% if (contact.address) { %}
		<p>
			{%= contact.address %}
		</p>
		{% endif %}
	</div>
{% } %}

{% if (!contact_list.length) { %}
	<p class="text-muted small">{%= __("No contacts added yet.") %}</p>
{% } %}

<p>
	<button class="btn btn-xs btn-default btn-contact">
		{{ __("New Contact") }}
	</button>
</p>
`;
